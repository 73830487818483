import {Component} from '@angular/core';
import {AppMainComponent} from "../app-main/app-main.component";
import {KeycloakService} from 'keycloak-angular';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {from, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-inlinemenu',
  templateUrl: './app-inline-menu.component.html',
  animations: [
    trigger('inline', [
      state('hidden', style({
        height: '0px',
        overflow: 'hidden'
      })),
      state('visible', style({
        height: '*',
      })),
      state('hiddenAnimated', style({
        height: '0px',
        overflow: 'hidden'
      })),
      state('visibleAnimated', style({
        height: '*',
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppInlineMenuComponent {
  isLogged: boolean = this.keyCloakService.isLoggedIn();

  constructor(
    public appMain: AppMainComponent,
    public keyCloakService: KeycloakService,
    private httpClient: HttpClient
  ) {
  }

  onLogout() {
    return this.httpClient.post<any>('/logout', []).subscribe(() => {
      document.location.reload();
    });
  }
}
