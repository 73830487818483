<div [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.activeInlineProfile}" *ngIf="isLogged">
  <ul class="layout-inline-menu-action-panel" [@inline]="appMain.activeInlineProfile ? 'visibleAnimated' : 'hiddenAnimated'">
    <li class="layout-inline-menu-action-item">
      <a (click)="onLogout()">
        <i class="pi pi-power-off pi-fw"></i>
        <span>Logout</span>
      </a>
    </li>
  </ul>
</div>

